import config from './production.js'

const conf = {
    ...config,
    carePortal: {
        urls: {
            adminAuthUrl: 'https://attus02.ulm.stg.m1amdocs.io',
        },
        agentClientId: 'HaloCTestRP',
        ssoClientId: 'haloeFederationClientID',
        ssoClientSecret: 'secret0.5477682322858153',
        OAuthParam: {
            extras: {},
        },
    },
    urls: {
        ...config.urls,
        apiUrl: 'https://h3git.ulm.stg.m1amdocs.io/platform/rest/v85',
        authUrl: 'https://h3git.ulm.stg.m1amdocs.io',
        userPreferencesUrl: 'https://h3git.ulm.stg.m1amdocs.io/customData',
        storefrontUrl: 'https://h3git.ulm.stg.m1amdocs.io/storefront',
        emi: {
            baseUrl: 'https://waf-h3git-emi-wa-s001.ifs.vubiquity.com/curation-api',
            homePath: '3.0/{population}/1',
            defaultPopulation: 'stg',
        },
        proxyUrl: 'https://waf-h3git-metadata-api-s001.ifs.vubiquity.com/maculosa/v3.1',
    },
    clientId: '09075944-790d-4146-aae4-8649be8e5217',
    encryption: {
        enabled: false,
    },
    googleAnalyticsConfig: {
        enabled: true,
        measurementId: 'G-86N87C76GB',
    },
    env: 'staging',
}

export default conf
